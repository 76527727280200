import Document from "../../components/v2/Document";
import BlogDocument from "../../components/_pages/v2/blog/BlogDocument";

const Blog = () => (
	<Document
		title="Blog - Self Improvement"
		ogData={{
			title: "Stay Nimble. Self Improvement Articles In Our Blog. - Stay Nimble",
			description: "Exercises and reflections that help improvement yourself in the world of work.",
			image: "https://s3.eu-west-2.amazonaws.com/static.staynimble.co.uk/assets/open-graph/preview.jpg",
			url: "/blog/self-improvement/",
		}}
	>
		<BlogDocument category="self-improvement" pageTitle="Self Improvement" />
	</Document>
);

export default Blog;
